import React, { useState, useEffect } from 'react';
import "../loader/loader.css"
import "./CS.css"
const CS = () => {


  return (
    <div className='loader_body flex_cs'>
      <div className='cs_txt'>COMING SOON</div>
    </div>
  );
};

export default CS;
