import React from "react";
import "./Sponsor.css";
import sgif from "./sponsor.gif";
import lic from "./lic.svg"
function Sponsor() {
  return (
    <div className="sponsor">
      <div className="s_str">
        <img src={sgif} className="sgif" />
        <img src={sgif} className="sgif" />
      </div>
      <div className="s_txt neon-blue">SPONSORS</div>

      <div className="logos_s">
        <div className="logo_e neon-yellow blink">
          <img
            src={lic}
            className="lg"
          />
          <div className="txt_s_s neon-blue">
            Official Associate Sponsor
          </div>
        </div>
        </div>
    </div>
  );
}

export default Sponsor;
